// @flow strict
import React from "react";
import { Link } from "gatsby";
import styles from "./Author.module.scss";
import { useSiteMetadata } from "../../../hooks";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles["author"]}>
      <p className={styles["author-text"]}>
        <Link to="/pages/about">
          <em>
            <b>{author.name}</b>
          </em>
        </Link>
      </p>
      <p className={styles["author__bio"]}>
        {author.bio.split("\n").join("  |  ")}
      </p>
    </div>
  );
};

export default Author;
