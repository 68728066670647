// @flow strict
import React from "react";
import { Link, navigate } from "gatsby";
import Author from "./Author";
import Comments from "./Comments";
import Content from "./Content";
import Meta from "./Meta";
import Tags from "./Tags";
import styles from "./Post.module.scss";
import type { Node } from "../../types";
import Copyright from "./../Sidebar/Copyright";
import Terms from "./../Sidebar/Terms";
import { useSiteMetadata } from "../../hooks";

type Props = {
  post: Node,
};

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, category } = post.frontmatter;
  const { copyright, terms } = useSiteMetadata();

  const showGuideLink = ["finance", "investment"].includes(
    category.toLowerCase()
  );
  return (
    <div className={styles["post"]}>
      <div className={styles["post__home-block"]}>
        <Link className={styles["post__home-button"]} to="/">
          All Articles
        </Link>
        {showGuideLink && (
          <Link
            className={styles["post__home-button"]}
            to="/the-reasonable-investor"
          >
            The Reasonable Investor (PT)
          </Link>
        )}
        {showGuideLink && (
          <Link
            className={styles["post__home-button"]}
            to="/pages/the-reasonable-subscriber"
          >
            Subscrever
          </Link>
        )}
      </div>

      <div className={styles["post__content"]}>
        <Content body={html} title={title} />
      </div>

      <div className={styles["post__footer"]}></div>

      <div className={styles["post__footer"]}>
        <Meta date={date} />
        <Author />
        {showGuideLink && (
        <Link
          className={styles["post__footer__subscribe"]}
          to="/pages/the-reasonable-subscriber"
        >
          Subscrever
        </Link>
        )}
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <div className={styles["post__footer__copyrights_terms"]}>
          <Copyright copyright={copyright} />
          <Terms terms={terms.text} url={terms.url} />
        </div>
      </div>

      <div className={styles["post__comments"]}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
